import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deposit: [],
};

//internally using immer lib (can create mutable state)
export const depositSlice = createSlice({
  name: "depositData",
  initialState,
  reducers: {
    
    updateAllDeposit: (state, action) => {
      state.deposit = action.payload;
    },
  },
});

// this is for dispatch
export const { updateAllDeposit } = depositSlice.actions;

// this is for configureStore
export default depositSlice.reducer;
