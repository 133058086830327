import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../redux-slice/user.slice";
import depositReducer from "../redux-slice/deposit.slice";
import withdrawReducer from "../redux-slice/withdraw.slice";
import walletReducer from "../redux-slice/wallet.slice";
import customizationReducer from "store/customizationReducer";

export const store = configureStore({
  reducer: {
    user: userReducer,
    deposit: depositReducer,
    withdraw: withdrawReducer,
    wallet: walletReducer,
    customization: customizationReducer,
  },
});
