// assets
import {
  IconBuildingBank,
  IconReportMoney,
  IconCashOff,
  IconUsers,
  IconWallet,
} from "@tabler/icons";

// constant
const icons = {
  IconBuildingBank,
  IconReportMoney,
  IconCashOff,
  IconUsers,
  IconWallet
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: "utilities",
  title: "Utilities",
  type: "group",
  children: [
    {
      id: "desposit",
      title: "Deposit Details",
      icon: icons.IconReportMoney,
      type: "collapse",
      children: [
        {
          id: "all-deposits",
          title: "All Deposit",
          type: "item",
          url: "/deposits",
          breadcrumbs: true,
        },
        {
          id: "deposit-request",
          title: "Deposit Request",
          type: "item",
          url: "/deposit-request",
          breadcrumbs: true,
        },
        {
          id: "rejected-deposit",
          title: "Rejected Deposit",
          type: "item",
          url: "/rejected-deposit",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "withdraw",
      title: "Withdraw Details",
      icon: icons.IconCashOff,
      type: "collapse",
      children: [
        {
          id: "all-withdraw",
          title: "All Withdraw",
          type: "item",
          url: "/withdraw",
          breadcrumbs: true,
        },
        {
          id: "withdraw-request",
          title: "Withdraw Request",
          type: "item",
          url: "/withdraw-request",
          breadcrumbs: true,
        },
        {
          id: "rejected-withdraw",
          title: "Rejected Withdraw",
          type: "item",
          url: "/rejected-withdraw",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "user-wallet",
      title: "User Wallet",
      icon: icons.IconWallet,
      type: "item",
      url: "/wallet",
    },
    {
      id: "all-user",
      title: "User",
      icon: icons.IconUsers,
      type: "item",
      url: "/users",
    },
    {
      id: "account-details",
      title: "Account Details",
      icon: icons.IconBuildingBank,
      type: "item",
      url: "/account-details",
    },
  ],
};

export default utilities;
