
import ApiRoutes from "../configs/endpoints.config";
import HttpClient from "./index.api";
import { getTokenLocal } from "utils/localStorage.util";
const baseURL = process.env.REACT_APP_API_URL;

class Deposit extends HttpClient {
  constructor() {
    super(baseURL);
    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${getTokenLocal()}`;
      // config.headers["authkey"] = process.env.REACT_APP_AUTH_KEY;
      return config;
    });
  };

  _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (response) => {
        return Promise.resolve(response);
      }
    );
  };

  AllDepositConfig = ApiRoutes.Deposit.All;
  editDepositConfig = ApiRoutes.Deposit.EditDeposit;
  getDepositByIdConfig = ApiRoutes.Deposit.GetDepositById;

  getAllDeposit = async (data) => {
    return this.instance({
      method: this.AllDepositConfig.Method,
      url: this.AllDepositConfig.Endpoint,
      headers: {},
      data: data,
    });
  };

  editDeposit = async (data) => {
    return this.instance({
      method: this.editDepositConfig.Method,
      url: this.editDepositConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
  getDepositById = async (data) => {
    return this.instance({
      method: this.getDepositByIdConfig.Method,
      url: this.getDepositByIdConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
}

export default Deposit;
