import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wallet: [],
};

//internally using immer lib (can create mutable state)
export const walletSlice = createSlice({
  name: "walletData",
  initialState,
  reducers: {
   
    updateAllWallet: (state, action) => {
      state.wallet = action.payload;
    },
  },
});

// this is for dispatch
export const {  updateAllWallet } = walletSlice.actions;

// this is for configureStore
export default walletSlice.reducer;
