import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  withdraw: [],
};

//internally using immer lib (can create mutable state)
export const withdrawSlice = createSlice({
  name: "withdrawData",
  initialState,
  reducers: {
    
    updateAllWithdraw: (state, action) => {
      state.withdraw = action.payload;
    },
  },
});

// this is for dispatch
export const { updateAllWithdraw } = withdrawSlice.actions;

// this is for configureStore
export default withdrawSlice.reducer;
