import { lazy } from "react";
// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
// dashboard routing
const Dashboard = Loadable(lazy(() => import("views/dashboard/Default")));


//Account Details Route
const AccountDetails = Loadable(lazy(() => import("views/utilities/account-details")));
const EditAccountDetails = Loadable(
  lazy(() => import("views/utilities/account-details/edit-account-details"))
);

const AllDeposit = Loadable(lazy(() => import("views/utilities/deposit/all-deposit")));
const DepositRequest = Loadable(lazy(() => import("views/utilities/deposit/deposit-request")));
const EditDeposit = Loadable(lazy(() => import("views/utilities/deposit/edit-deposit")));
const RejectedDeposit = Loadable(lazy(() => import("views/utilities/deposit/rejected-deposit")));


const AllWithdraw = Loadable(lazy(() => import("views/utilities/withdraw/all-withdraw")));
const WithdrawRequest = Loadable(lazy(() => import("views/utilities/withdraw/withdraw-request")));
const EditWithdraw = Loadable(lazy(() => import("views/utilities/withdraw/edit-withdraw")));
const RejectedWithdraw = Loadable(lazy(() => import("views/utilities/withdraw/rejected-withdraw")));


const User = Loadable(lazy(() => import("views/utilities/user")));
const EditUser = Loadable(lazy(() => import("views/utilities/user/edit-user")));
const Wallet = Loadable(lazy(() => import("views/utilities/wallet")));
const BalanceSheet = Loadable(lazy(() => import("views/utilities/balance-sheet")));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/dashboard",
      element: <Dashboard />,
    },

    {
      children: [
        {
          path: "account-details",
          element: <AccountDetails />,
        },

        {
          path: "edit-account-details/:id",
          element: <EditAccountDetails />,
        },
      ],
    },

    {
      children: [
        {
          path: "deposits",
          element: <AllDeposit />,
        },
        {
          path: "deposit-request",
          element: <DepositRequest />,
        },
        {
          path: "rejected-deposit",
          element: <RejectedDeposit />,
        },
        {
          path: "edit-deposit/:id",
          element: <EditDeposit />,
        },
      ],
    },
    {
      children: [
        {
          path: "withdraw",
          element: <AllWithdraw />,
        },

        {
          path: "withdraw-request",
          element: <WithdrawRequest />,
        },
        {
          path: "edit-withdraw/:id",
          element: <EditWithdraw />,
        },
        {
          path: "rejected-withdraw",
          element: <RejectedWithdraw />,
        },
      ],
    },
    {
      children: [
        {
          path: "wallet",
          element: <Wallet />,
        },
      ],
    },
    {
      children: [
        {
          path: "users",
          element: <User />,
        },
        {
          path: "edit-user/:id",
          element: <EditUser />,
        },
        {
          path: "balance-sheet/:id",
          element: <BalanceSheet />,
        },
      ],
    },
  ],
};

export default MainRoutes;

