import Deposit from "apis/deposit.api";

export const HttpMethod = {
  Get: "GET",
  Post: "POST",
  Put: "PUT",
  Patch: "PATCH",
  Delete: "DELETE",
};

const ApiRoutes = {
  Dashboard: {
    Data: {
      Endpoint: "/auth/dashboard",
      Method: HttpMethod.Post,
    },
  },
  Auth: {
    Login: {
      Endpoint: "/auth/login",
      Method: HttpMethod.Post,
    },
  },
  AccountDetails: {
    All: {
      Endpoint: "/accountDetails/getAllAccountDetails",
      Method: HttpMethod.Post,
    },
    EditAccountDetails: {
      Endpoint: "/accountDetails/editAccountDetails",
      Method: HttpMethod.Post,
    },
    GetAccountDetailsById: {
      Endpoint: "/accountDetails/getAccountDetailsById",
      Method: HttpMethod.Post,
    },
  },
  Deposit: {
    All: {
      Endpoint: "/deposit/getAllDeposit",
      Method: HttpMethod.Post,
    },
    EditDeposit: {
      Endpoint: "/deposit/editDeposit",
      Method: HttpMethod.Post,
    },
    GetDepositById: {
      Endpoint: "/deposit/getDepositById",
      Method: HttpMethod.Post,
    },
  },
  Withdraw: {
    All: {
      Endpoint: "/withdraw/getAllWithdraw",
      Method: HttpMethod.Post,
    },
    EditWithdraw: {
      Endpoint: "/withdraw/editWithdraw",
      Method: HttpMethod.Post,
    },
    GetWithdrawById: {
      Endpoint: "/withdraw/getWithdrawById",
      Method: HttpMethod.Post,
    },
  },
  User: {
    All: {
      Endpoint: "/user/getAllUser",
      Method: HttpMethod.Post,
    },
    EditUser: {
      Endpoint: "/user/editUser",
      Method: HttpMethod.Post,
    },
    GetUserById: {
      Endpoint: "/user/getUserById",
      Method: HttpMethod.Post,
    },
  },
  Wallet: {
    All: {
      Endpoint: "/wallet/getAllWallet",
      Method: HttpMethod.Post,
    },
    GetWalletById: {
      Endpoint: "/wallet/getWalletById",
      Method: HttpMethod.Post,
    },
  },
  BalanceSheet: {
    GetUserBalanceSheet: {
      Endpoint: "/balanceSheet/getUserBalanceSheet",
      Method: HttpMethod.Post,
    },
  },
};

export default ApiRoutes;
